import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from "react-router-dom";
import NavBar from '../NavBar';


const items = [{ link: "/logout", label: "Ausloggen" }]

const useStyles = makeStyles((theme) => ({

  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
      marginTop: "64px",
    },
  },
  box: {
    color: "#0B5AA1 !important",
    marginTop: "50px",
    marginBottom: "2%",
    maxWidth: "750px",
    margin: "50px 120px",
    margin: "auto",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",


    '&:hover': {
      color: "#073F71 !important"
    },

  },

  a: {
    color: "#0B5AA1 !important",

    '&:hover': {
      color: "#073F71 !important",

    }
  }

}));



const TITLE = 'Kick-Off 2021'


export default function Home(props) {
  const classes = useStyles()
  let { params } = useParams();
  const [open, setOpen] = React.useState(params);


  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <NavBar items={items} burger={true}/>

      <img className={classes.titleImage} src="https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/berner/assets/2104+Berner+-+Kick-Off+2021+-+Berner+-+Titelgrafik+v001.jpg" />

      <Container maxWidth="md">

        <div className={classes.box}>
          <div className="card-content" style={{ padding: '28px' }}>
            <h2>Herzlich willkommen zur Kick-Off 2021!</h2>
            <br></br>
            <p>Solltest du technische Probleme haben, kontaktiere uns bitte über den Chat.</p>
            <br />
            <p>Solltest du Fragen zum Inhalt haben, beantworten wir diese gerne im Nachgang. Bitte sende hierzu deine Frage an  <a className={classes.a} href="mailto:event@berner.de">event@berner.de</a>. </p>
            <br />
            <p>Wir wünschen dir viel Spaß beim Zuschauen!</p>
            <br></br>
          </div>
          <div className="video-footer card-footer">
            <Link to={"/stream"}>
            <Button
              variant="contained"
              size="large"
              color="primary"
            >Kick Off Berner Deutschland </Button>
            </Link>
            
          </div>
        </div>
      </Container>


      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Die Berner Kick-Off Region Central findet hier am 15.04.2021 ab 8:30 Uhr statt. Der Stream dazu beginnt wenige Minuten vorher. Du kannst dich jetzt schon auf ein großartiges Event freuen.</p>

          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Link to={"/logout"}>
            <Button color="primary">
              Ausloggen
          </Button>
          </Link>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions >

      </Dialog>

    </>
  )
}

