import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { useNavState } from '../context/navigation';





const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: '84px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#00000080',
  },
  dialogActions: {
    color: 'primary',
  },
  title: {
    flexGrow: 2,
    color: "#0B5AA1",
    fontWeight: 600
  },

  logo: {
    flexGrow: 2,
    maxHeight: "30px",
    alignSelf: "center",
    backgroundSize: "contain",
    marginRight: theme.spacing(4),
  },
  navButton: {
    fontWeight: 600
  },
  drawerPaper: {
    width: drawerWidth,
  },

}));


const drawerWidth = 240;



export default function NavBar({items, burger}) {
  const classes = useStyles()
  const theme = useTheme();

  const  navList  = useNavState()
  const [drawerState, setDrawerState] = React.useState(false);
  const md = useMediaQuery(theme.breakpoints.up('md'));



const drawerItems =(
  <List>
{items.map(item => 
  <Link to={item.link}>
    <ListItem button key={item.label}>
      <ListItemText primary={<Typography color={"primary"}>{item.label}</Typography>} />
    </ListItem>
    </Link>
)}
  </List>
)


const appBarItems =(
  <>
{items.map(item => 

    <Link to={item.link}>
    <Button className={classes.navButton} color="primary" >{item.label} </Button>
  </Link>
)}
</>
)

  const toggleDrawer = () => {
    setDrawerState(!drawerState)
  }



  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed" className="app-bar">
          <Toolbar>
            <Box display='flex' flexGrow={1}>
              <Link to={"/"} >
                <img
                  src="https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/berner/assets/2104+Berner+-+Kick-Off+2021+-+Berner+-+Logo+v001.png" alt=''
                  className={classes.logo} />
              </Link>
              {md &&
                <Typography variant="h5" className={classes.title}>
                  Kick-Off 2021
    </Typography>}
            </Box>
            {md ? appBarItems : ( burger && <IconButton className={classes.navButton} color="primary" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>)}

          </Toolbar>
        </AppBar>


{burger && 
  <Drawer
          variant="temporary"
          open={drawerState}
          onClose={toggleDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerItems}
        </Drawer>
}
        

      </div>
    </>
  )
}

